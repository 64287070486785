// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-a-radical-new-approach-to-productivity-tsx": () => import("./../../../src/pages/a-radical-new-approach-to-productivity.tsx" /* webpackChunkName: "component---src-pages-a-radical-new-approach-to-productivity-tsx" */),
  "component---src-pages-donwloads-mac-apple-silicon-tsx": () => import("./../../../src/pages/donwloads/mac-apple-silicon.tsx" /* webpackChunkName: "component---src-pages-donwloads-mac-apple-silicon-tsx" */),
  "component---src-pages-donwloads-mac-tsx": () => import("./../../../src/pages/donwloads/mac.tsx" /* webpackChunkName: "component---src-pages-donwloads-mac-tsx" */),
  "component---src-pages-donwloads-ubuntu-tsx": () => import("./../../../src/pages/donwloads/ubuntu.tsx" /* webpackChunkName: "component---src-pages-donwloads-ubuntu-tsx" */),
  "component---src-pages-download-tsx": () => import("./../../../src/pages/download.tsx" /* webpackChunkName: "component---src-pages-download-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("./../../../src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */),
  "component---src-templates-docs-tsx": () => import("./../../../src/templates/docs.tsx" /* webpackChunkName: "component---src-templates-docs-tsx" */),
  "component---src-templates-markdownpage-tsx": () => import("./../../../src/templates/markdownpage.tsx" /* webpackChunkName: "component---src-templates-markdownpage-tsx" */)
}

